<template>
    <footer class="site-footer">
        <div class="container">
            <div class="row">
                <ul class="copyright">
                    <li :key="index" v-for="(list, index) in getFooter">
                        <span v-if="list.link[list.target].length === 0">{{ commonTranslations[list.label] }}</span>
                        <span v-else>
                            <a :href="list.link[list.target]" :target="`_${list.target}`" v-html="commonTranslations[list.label]"></a>
                        </span>
                    </li>
                    <li><a @click="scrollToTop"><i class="fa fa-arrow-circle-up"></i> <strong>{{ commonTranslations['footer-back-to-top'] }}</strong></a></li>
                </ul>
            </div>
        </div>
    </footer>
</template>

<script>
    import {mapGetters} from 'vuex';

    export default {
        data() {
            return {
                height: {
                    body_height: window.$('body').prop('scrollHeight'),
                    window_height: window.$(window).height()
                }
            }
        },
        computed: {
            ...mapGetters(['commonTranslations','getFooter'])
        },
        methods: {
            scrollToTop() {
                window.scrollTo({top: 0, behavior :'smooth'})
            }
        }
    }
</script>

<style scoped lang="css">
    footer {
        width: 100%;
    }

    footer .container {
        margin-bottom: 0;
    }

    .copyright li:first-child {
        text-transform: none !important;
    }

    footer a {
        color: #55585c;
        cursor: pointer;
    }
</style>
<template>
    <div id="modal-assist-info" class="modal fade in" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content" v-if="$route.params.where">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">×</button>
                    <h4 v-html="getTitle"></h4>
                </div>
                <div class="modal-body" style="overflow: hidden;">
                    <div v-html="getText"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState,mapGetters,mapActions} from 'vuex';

    export default {
        props: {
            mobile: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                modal: {
                    name: 'modal-assist-info'
                },
                content: {
                    darkweb: {},
                    publicweb: {}
                },
                personal_or_finance: ''
            }
        },
        watch: {
            '$route.params' (to, from) {
                if (to.where !== undefined) {
                    window.$('#modal-assist-info').modal('show')
                }

                this.policies()

                from
            }
        },
        methods: {
            ...mapActions(['getOnlineSupportAssistance']),
            policies() {
                const policies = this.customer.dashboard.identity_risk_page.policies

                for (let i in policies) {
                    if (this.$route.params.found == policies[i].target_dbfield) {
                        this.personal_or_financial = parseInt(policies[i].financial) > 0 ? 'financial' : 'personal';

                        if (!parseInt(policies[i].financial) && policies[i].target_dbfield !== 'email') {
                            this.personal_or_financial = 'other'
                        }

                        break
                    }
                }
            }
        },
        computed: {
            ...mapState(['customer']),
            ...mapGetters(['onlineSupportAssistance']),
            getTitle() {
                return this.content[this.$route.params.where].groups[this.personal_or_financial].title
            },
            getText() {
                return this.content[this.$route.params.where].groups[this.personal_or_financial].text
            }
        },
        mounted() {
            window.$(`#${this.modal.name}`).on('hide.bs.modal', ()=> {
                this.$router.push(`${this.mobile ? '/app' : '/dashboard'}/identity-risk`)
            })
        },
        async created() {
            if (this.onlineSupportAssistance.length === 0) {
                await this.getOnlineSupportAssistance()
            }

            let assistance = this.onlineSupportAssistance

            for (let i in assistance) {
                let index = Object.keys(this.content)[i];

                if (index !== undefined) {
                    this.content[index].title = assistance[i].title;

                    this.content[index].groups = {personal : {}, financial : {}, other: {}};

                    if (assistance[i].groups[0] !== undefined) {
                        this.content[index].groups.personal = {
                            title: assistance[i].groups[0].title,
                            text: assistance[i].groups[0].text
                        };
                    }

                    if (assistance[i].groups[1] !== undefined) {
                        this.content[index].groups.financial = {
                            title: assistance[i].groups[1].title,
                            text: assistance[i].groups[1].text
                        };
                    }

                    if (assistance[i].groups[2] !== undefined) {
                        this.content[index].groups.other = {
                            title: assistance[i].groups[2].title,
                            text: assistance[i].groups[2].text
                        };
                    }
                }
            }

            setTimeout(()=>{
                if (this.$route.params.where !== undefined) {
                    window.$(`#${this.modal.name}`).modal('show')
                }
            }, 1000)
        }
    }
</script>
<template>
    <div>
        <video-popup-window :enabled="video_popup" :videoOptions="videoOptions" @disableVideo="disableVideo"></video-popup-window>

        <div class="page-wrapper canvas" :class="{ fixed: floating_menu }">
            <div class="page-wrapper-row">
                <div class="page-wrapper-top">
                    <div class="page-header" :class="{ shadow: shadow }">
                        <div class="page-header-top" :class="`height_${page_header_height}`">
                            <div class="container">
                                <div class="page-logo">
                                    <Logo :logo_height="logo_height" />
                                </div>

                                <button v-if="display_menu" type="button" class="navbar-toggler" data-toggle="offcanvas" data-recalc="false" data-target=".navmenu" data-canvas="body">
                                    <span class="navbar-toggle-icon">
                                        <span class="lnr lnr-menu"></span>
                                    </span>
                                </button>

                                <!-- Menu -->
                                <div class="hor-menu" v-if="display_menu">
                                    <ul class="pull-right menu nav navbar-nav">
                                        <li v-show="parent.active" class="menu-dropdown" v-bind:key="index" v-for="(parent, index) in menu">
                                            <div data-toggle="dropdown" @click="iconClick(parent.link)" data-hover="dropdown" class="row" style="margin: 0;">
                                                <svg class="flag-icon"><use :href="`#${parent.icon}`"></use></svg>
                                            </div>
                                            <a href="javascript:;" class="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown">{{ parent.name }}</a>
                                            <ul v-if="index !== 'videos'" class="dropdown-menu">
                                                <li aria-haspopup="true" :class="child.class" v-bind:key="key" v-for="(child, key) in parent.dropdown">
                                                    <p v-if="child.active === false">{{ child.name }}</p>
                                                    <a v-if="child.active !== false" :class="`nav-link ${index === 'videos' ? 'popup-video' : ''}`" :href="child.link" :target="child.target === undefined ? '_self' : child.target"><svg class="flag-icon"><use :href="`#${child.icon}`"></use></svg>{{ child.name }}</a>
                                                </li>
                                            </ul>
                                            <ul v-else class="dropdown-menu">
                                                <li aria-haspopup="true" :class="child.class" v-bind:key="key" v-for="(child, key) in parent.dropdown">
                                                    <a v-if="child.active !== false" class="nav-link" @click="openPopupVideoWithSource(child.link, child.name)"><svg class="flag-icon"><use :href="`#${child.icon}`"></use></svg>{{ child.name }}</a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="menu-dropdown" v-if="availableLanguages.length > 1">
                                            <div data-toggle="dropdown" data-hover="dropdown" class="row" style="margin: 0;">
                                                <svg class="flag-icon"><use :href="`#${activeLanguage.icon}`"></use></svg>
                                            </div>
                                            <a href="javascript:;" class="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown">{{ activeLanguage.name }}</a>
                                            <ul class="dropdown-menu">
                                                <li v-bind:key="key" v-for="(language, key) in dropdownLanguages">
                                                    <a class="nav-link" @click="languageChanged(language)"><svg class="flag-icon"><use :href="`#${language.icon}`"></use></svg>{{ language.name }}</a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="page-header-menu">
                            <div class="member-id" v-if="session.logged_in && display_menu">
                                <span style="float: left;">{{ commonTranslations['member-id'] }}: <strong>{{ customer.details.member_id }}</strong></span>
                                <FBLoader style="float: left;" v-if="!customer.details.member_id" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Logo from '@/components/Logo'
    import Menu from '@/components/Menu'
    import FBLoader from '@/components/FBLoader'
    import {mapState,mapGetters} from 'vuex';

    import VideoPopupWindow from '@/components/VideoPopupWindow'

    export default {
        mixins: [Menu],
        props: {
            display_menu: {
                type: Boolean,
                default: true
            },
            floating_menu: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                logo_height: 75,
                page_header_height: 90,
                shadow: false
            }
        },
        components: {
            Logo,
            FBLoader,
            VideoPopupWindow
        },
        computed: {
            ...mapGetters(['commonTranslations']),
            ...mapState(['general', 'session', 'customer']),
        },
        methods: {
            onScroll() {
                this.windowTop = window.top.scrollY

                if (this.windowTop > 100) {
                    this.logo_height = 55
                    this.page_header_height = 75
                    this.shadow = true
                } else if (window.innerWidth > 500) {
                    this.logo_height = this.general.logo_height
                    this.page_header_height = 90
                    this.shadow = false
                }
            },
            windowResize() {
                if (window.innerWidth < 500) {
                    this.logo_height = 55
                    this.video_width = window.innerWidth - 50
                } else {
                    this.video_width = window.innerWidth / 2
                }
            }
        },
        mounted() {
            if (this.$route.href.match('auth') === null) {
                window.addEventListener("scroll", this.onScroll)
            }

            window.addEventListener("resize", this.windowResize)
            this.windowResize()
        },
        unmounted() {
            window.removeEventListener("resize", this.windowResize);
        },
        beforeMount() {
            if (this.$route.href.match('auth') !== null) {
                window.removeEventListener("scroll", this.onScroll)
            }
        },
    }
</script>

<style scoped lang="scss">
    .shadow {
        box-shadow: 0 0 10px 0 RGB(0 0 0 / 30%) !important;
    }

    .container {
        height: 100% !important;
    }

    .height_90 {
        height: 90px !important;
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
    }

    .height_75 {
        height: 75px !important;
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
    }

    .page-wrapper.fixed {
        position: fixed;
        top: 0;
        min-height: 0%;
        height: 110px;
    }

    .menu-dropdown.language a.dropdown-toggle:hover {
        background-color: none !important;
    }

    .page-header {
        margin: 0;
        background-color: #FFF;
    }

    .navbar-toggler {
        position: absolute;
        right: 20px;
        top: 20px;
        align-self: flex-start;
        padding: 0.45rem 0.75rem 0 0.75rem !important;
        display: none;
        border: 0 !important;
        border-radius: 0 !important;
    }

    button.navbar-toggle {
        background-color: $base-color;
        cursor: pointer;
        margin: 10px 0 0 0;
    }

    button.navbar-toggle span {
        background-color: #FFF;
    }

    .navbar-toggler-icon {
        display: inline-block;
        vertical-align: middle;
        content: "";
        background: no-repeat center center;
        background-size: 100% 100%;
    }

    .lnr {
        font-family: Linearicons-Free;
        speak: none;
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
    }

    .lnr-menu:before {
        content: "\e871";
    }

    @media screen and (max-width: 991px) {
        .navbar-toggler-icon {
            margin: 0;
            width: auto;
            height: auto;
            padding: 3px;
        }

        .navbar-toggler {
            display: block;
            color: #fff;
            cursor: pointer;
        }
    }
</style>